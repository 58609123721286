<template>
  <div class="container-info-cupo">
    <div class="container-max-width w-100">
      <info-cupo />
    </div>
  </div>
</template>
<script>
import InfoCupo from '@/components/infoCupo/InfoCupo'
export default {
  components: { InfoCupo },
  beforeCreate() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover-simple'
    this.$store.state.layouts.menuLayoutClover = 'menu-layout-simple'
  },
  beforeDestroy() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover'
    this.$store.state.layouts.menuLayoutClover = 'menu-clover'
  },
}
</script>
